#templates-panel {
  padding: 8px;
}

#templates-panel h2 {
  text-align: left;
  padding: 16px 8px;
  margin: 0;
}

#templates-cards-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#templates-cards-container button {
  position: relative;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

#templates-cards-container > .card {
  flex-direction: column;
  width: 50%;
  background-color: initial;
  color: #fff;
  margin-bottom: 16px;
}

#templates-cards-container .card button {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #fff;
  color: black;
  text-align: center;
  padding: 0px 0px 1px 1px;
}

#templates-cards-container .card button:hover {
  background-color: rgb(2, 152, 214);
  color: #fff;
  transition: 0.1s;
}

#templates-cards-container .card img {
  pointer-events: none;
  width: 100%;
}

#templates-cards-container .card div {
  padding: 8px 0;
}
