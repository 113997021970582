body {
  margin: 0;
  font-family: "Maison Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Maison Neue";
  src: url("assets/fonts/MaisonNeue-Medium.woff2") format("woff2"), url("assets/fonts/MaisonNeue-Medium.woff") format("woff"),
    url("assets/fonts/MaisonNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
